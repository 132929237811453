<template>
  <div class="edit-level">
    <Modal v-model="editStatus" class-name="vertical-center-modal" :title="(editData.id?'添加':'编辑')+'等级'" width="440" @on-ok="commitData" okText="提交" :loading="subLoading" :closable="false" :mask-closable="false" >
      <Form label-position="left" :label-width="90" ref="fValidate" :model="editData" :rules="ruleValid" >
        <FormItem label="等级" style="margin-bottom: 10px" prop="level">
          <Input :value="editData.level" disabled></Input>
        </FormItem>
        <FormItem label="推荐人数" style="margin-bottom: 10px" prop="rec_num">
          <InputNumber style="width: 313px" :active-change="false" :precision="0" :max="99999999" :min="0" v-model="editData.rec_num"></InputNumber>
        </FormItem>
        <FormItem label="等级名称" style="margin-bottom: 10px" prop="level_name">
          <Input style="width: 313px" :active-change="false" maxlength="15" v-model="editData.level_name"></Input>
        </FormItem>
        <FormItem label="业绩起始值" style="margin-bottom: 10px" prop="start_money">
          <InputNumber style="width: 313px" :active-change="false" :precision="2" :max="999999999999" :min="0" v-model="editData.start_money"></InputNumber>
        </FormItem>
        <FormItem label="业绩结束值" style="margin-bottom: 10px" prop="end_money">
          <InputNumber style="width: 313px" :active-change="false" :precision="2" :max="999999999999" :min="1" v-model="editData.end_money"></InputNumber>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
var editData =  {id:0,level:1, rec_num:null, level_name:null,start_money:null, end_money:null};
export default {
  name: 'editLevel',
  data(){
    const check_start_money = (rule, value, callback) => {
      if(parseFloat(value) > parseFloat(this.editData.end_money)) callback(new Error('不能大于结束业绩'));
      else{
        this.$refs.fValidate.validateField('end_money');
        callback();
      }
    };
    const check_end_money = (rule, value, callback) => {
      if(parseFloat(value) < parseFloat(this.editData.start_money)) callback(new Error('不能小于起始业绩'));
      else{
        this.$refs.fValidate.validateField('start_money');
        callback();
      }
    };
    return {
      editStatus:false,
      subLoading:true,
      editData: JSON.parse(JSON.stringify(editData)),
      ruleValid: {
        level: [
          { required: true,type: 'number', message: '此项必填', trigger: 'blur' }
        ],
        rec_num: [
          { required: true,type: 'number', message: '此项必填', trigger: 'blur' }
        ],
        level_name: [
          { required: true,type: 'string', message: '此项必填', trigger: 'blur' }
        ],
        start_money: [
          { required: true,type: 'number', message: '此项必填', trigger: 'blur' },
          { validator:check_start_money, trigger: 'blur' }
        ],
        end_money: [
          { required: true,type: 'number', message: '此项必填', trigger: 'blur' },
          { validator:check_end_money, trigger: 'blur' }
        ]
      }
    }
  },
  watch:{
    subLoading(e){
      if(!e)
        setTimeout(()=>{
          this.subLoading = true;
        },50)
    }
  },
  methods:{
    init:function (data,level,nextStartMoney){
      this.$refs.fValidate.resetFields();
      if(data){
        this.editData = data;
      }else{
        this.editData = JSON.parse(JSON.stringify(editData));
        this.editData['level'] = level;
        this.editData['start_money'] = parseFloat(nextStartMoney);
      }
      this.editStatus = true;
    },
    commitData:function(){
      let _this = this;
      this.$refs.fValidate.validate((valid) => {
        if (valid) {
          this.requestApi('/adm/comUserLevel',{editData:this.editData}).then(function (res){
            _this.subLoading = false;
            if(res.status==1){
              _this.$Message.success(res.msg);
              _this.$emit('saveOk', {reData:res.data});
            }else{
              _this.subLoading = false;
              _this.$Message.error(res.msg);
            }
          })
        }else{
          this.subLoading = false;
          this.$Message.error('数据未完善!');
        }
      })
    },
    close:function(){
      this.editStatus = false;
    }
  }
}
</script>
<style lang="less">
  .edit-level{}
</style>