<template>
  <div class="user-level">
    <div class="breadcrumb-m">
      <div class="left">
        <Breadcrumb>
          <BreadcrumbItem to="/">主控台</BreadcrumbItem>
          <BreadcrumbItem>提成配置</BreadcrumbItem>
          <BreadcrumbItem>等级管理</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div class="right">
        <Button type="info" icon="ios-add" @click="editLevel(-1)">添加等级</Button>
      </div>
    </div>
    <Table size="small" :columns="columns" :data="data" :loading="loading">
      <template slot-scope="item" slot="action">
        <Button size="small" type="primary" @click="editLevel(item.index)">编辑</Button>
        <Divider type="vertical" />
        <Button size="small" type="warning" :disabled="!!item.index" @click="delLevel(item.row.id)">删除</Button>
        <Divider type="vertical" />
        <Button size="small" v-if="item.row.update_user_level_status" disabled ghost type="primary">{{item.row.update_user_level_status==1?'已同步会员等级':'会员等级同步中'}}</Button>
        <Button size="small" v-else  ghost type="primary" @click="syncLevel(item.index)">未同步会员等级</Button>
      </template>
    </Table>
    <div class="footer-m">
      <Page class-name="pagew page-r" :total="total" :current="page" show-total show-elevator show-sizer :page-size="pageSize" :page-size-opts="[10,20,30,50,100,200]" @on-change="changePage" @on-page-size-change="changePageSize"/>
    </div>
    <edit-level ref="editLevelRef" @saveOk="saveOk"></edit-level>
  </div>
</template>
<script>
import editLevel from "./edit_level";
export default {
  name: 'userLevel',
  components:{
    editLevel
  },
  data(){
    return {
      columns:[
        {title: '等级',key: 'level',minWidth:80,align:'center'},
        {title: '等级名称',key: 'level_name',minWidth:80,align:'center'},
        {title: '推荐人数',key: 'rec_num',minWidth:80,align:'center'},
        {title: '起始业绩',key:'start_money', minWidth:150,align:'center'},
        {title: '结束业绩',key: 'end_money',minWidth:150,align:'center'},
        {title: '操作',slot:'action',width:250,align:'center'}
      ],
      data:[],
      loading:false,
      total:0,
      pageSize:10,
      page:1,
      nextLevel:1,
      nextStartMoney:1
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (){
      let _this = this;
      this.loading = true;
      this.requestApi('/adm/userLevel', {page:this.page,page_size:this.pageSize}).then(function (res){
        _this.loading = false;
        if(res.status==1){
          _this.total = res.data.page.total;
          _this.pageSize = res.data.page.limit;
          _this.data = res.data.list;
          _this.nextLevel = res.data.next_level;
          _this.nextStartMoney = res.data.next_start_money;
        }
      })
    },
    /**
     * 同步用户等级
     */
    syncLevel:function(index){
      if(this.data[index].update_user_level_status) return false;
      this.data[index].update_user_level_status = 2;
      let _this = this;
      this.requestApi('/adm/syncUserLevel', {id:this.data[index].id}).then(function (res){
          _this.data[index].update_user_level_status = res.status==1?1:0;
      })
    },
    /**
     * 删除等级
     */
    delLevel:function(id){
      let _this = this;
      this.$Modal.confirm({
        title: '提示',
        content: '确定要删除吗？',
        onOk: () => {
          this.requestApi('/adm/delUserLevel', {id:id}).then(function (res) {
            if(res.status == 1){
              _this.$Message.success('删除成功!');
              _this.init();
            } else
              _this.$Message.error('删除失败!');
          })
        }
      });
    },
    /**
     * 编辑等级
     */
    editLevel:function (index){
        this.$refs.editLevelRef.init(index == -1?null:JSON.parse(JSON.stringify(this.data[index])),this.nextLevel,this.nextStartMoney);
    },
    saveOk:function(){
      this.$refs.editLevelRef.close();
      this.init();
    },
    changePage:function (page){
      this.page = page;
      this.init();
    },
    changePageSize:function (pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.init();
    }
  }
}
</script>